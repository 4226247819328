.d-flex {
  display: flex;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.section {
  width: 100%;
  height: calc(100vh - 5.6rem);
  @include respond(phone) {
    height: 100vh;
  }
}

.hidden {
  opacity: 0;
}

.hidden--mobile {
  @include respond(phone) {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.fade-in-mobile {
  @include respond(phone) {
    animation-name: fadeIn;
    animation-duration: 0.6s;
  }
}

.fade-out-mobile {
  @include respond(phone) {
    animation-name: fadeOut;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.2s;
}

.c-black {
  transition: color 0.1s;
  color: $black;
}

.c-white {
  transition: color 0.1s;
  color: $white;
}

.underline {
  text-decoration: underline !important;
}

.mobile {
  @include respond(min-phone) {
    display: none;
  }
}

.desktop {
  @include respond(phone) {
    display: none;
  }
}

.tab-port-min {
  @include respond(min-tab-port) {
    display: none;
  }
}

.tab-port-max {
  @include respond(tab-port) {
    display: none;
  }
}

.tab-land-min {
  @include respond(min-tab-land) {
    display: none;
  }
}

.tab-land-max {
  @include respond(tab-land) {
    display: none;
  }
}

.header-placeholder {
  height: 9rem;
  width: 100%;
  background: transparent;
  @include respond(phone) {
    height: 12rem;
  }
}
