.about {
  background: $light-grey;
  align-items: center;
  justify-content: flex-start;
  @include respond(min-phone) {
    min-height: calc(700px - 5.6rem);
  }

  &__container {
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @include small-container;
    @include respond(phone) {
      width: 100%;
    }
  }

  &__title {
    margin: 0 auto 50px;
    align-items: center;
    justify-content: center;

    @include respond(tab-land) {
      margin-bottom: 40px;
    }
    @include respond(big-desktop) {
      margin-bottom: 65px;
    }
    @include respond(huge-desktop) {
      margin-bottom: 80px;
    }
    &--mobile {
      margin: 0 auto 60px;
    }
  }

  &__fatto {
    @include title;
    text-transform: uppercase;
    margin-right: 11px;
    @include respond(tab-land) {
      margin-right: 8px;
    }
    @include respond(tab-port) {
      margin-right: 6px;
    }
    @include respond(big-desktop) {
      margin-right: 13px;
    }
    @include respond(huge-desktop) {
      margin-right: 15px;
    }
  }

  &__amore {
    margin-top: 3px;
    height: 30px;
    @include respond(tab-land) {
      height: 23px;
    }
    @include respond(tab-port) {
      margin-top: 2px;
      height: 19px;
    }
    @include respond(big-desktop) {
      margin-top: 6px;
      height: 35px;
    }
    @include respond(huge-desktop) {
      margin-top: 7px;
      height: 44px;
    }
  }

  &__content {
    align-items: stretch;
    justify-content: space-between;
    @include respond(tab-port) {
      justify-content: center;
      max-width: 300px;
    }
  }

  &__description {
    width: 45.5%;
    justify-content: space-between;

    @include respond(tab-port) {
      width: 100%;
      justify-content: center;
    }
  }

  &__img {
    width: 100%;
    padding: 4px;

    &-container {
      width: 45.5%;
      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &__paragraph,
  &__signature {
    width: 100%;
    @include paragraph;
    @include respond(tab-port) {
      text-align: center;
      width: 100%;
    }
  }

  &__paragraph {
    @include respond(tab-port) {
      margin-bottom: 3rem;
    }
    @include respond(phone) {
      padding: 0 14px;
    }
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  &__signature {
    width: 100%;
    @include respond(tab-port) {
      justify-content: center;
    }
    img {
      width: 15px;
      margin: 1px 4px 0 6px;
      padding-top: 1px;
      @include respond(tab-land) {
        width: 12px;
        margin: 0px 4px 0 6px;
      }
      @include respond(big-desktop) {
        width: 18px;
        margin: 1px 4px 0 9px;
      }
      @include respond(huge-desktop) {
        width: 21px;
        margin: 2px 4px 0 9px;
      }
    }
  }
}
