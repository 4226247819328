@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel {
  width: 100% !important;
  height: calc(100vh - 5.6rem) !important;
  @include respond(phone) {
    height: 100vh !important;
  }

  .slick-slider {
    height: 100% !important;

    .slick-slide,
    .slick-track,
    .slick-list {
      height: calc(100vh - 5.6rem) !important;
      @include respond(phone) {
        height: 100vh !important;
      }
      div {
        height: calc(100vh - 5.6rem);
        @include respond(phone) {
          height: 100vh !important;
        }
      }
    }
  }
  &__img {
    height: 100%;
    object-fit: cover !important;
  }

  .slick-dots {
    bottom: 4.2rem;
    @include respond(phone) {
      display: none !important;
    }

    li {
      margin: 0 0.7rem;
      width: 1rem;
      height: 1rem;

      .carousel__dot {
        @include btn-unstyled;
        width: 1rem;
        height: 1rem;
        border: 1px solid $white;
        border-radius: 50%;
        &::before {
          display: none;
        }
      }
    }
    .slick-active {
      button {
        background: white;
        &::before {
          display: none;
        }
      }
    }
  }
}
