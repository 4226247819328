@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin btn-unstyled {
  border: none;
  background-color: none;
  outline: none;
  background-repeat: no-repeat;
  content: none;
  text-decoration: none;
}

@mixin orange-btn {
  width: 171px;
  height: 44px;
  background: $orange;
  color: $black;
  text-transform: uppercase;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  @include btn-font;
  span {
    padding: 0 0 3px;
  }
}

@mixin shallow-btn {
  background: transparent;
  color: $white;
  border: 1px solid $white;
  text-transform: uppercase;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 44px;

  span {
    padding: 0 0 3px;
  }
}

@mixin big-container {
  width: 1006px;
  @include respond(tab-land) {
    width: 766px;
  }
  @include respond(tab-port) {
    width: 557px;
  }
  @include respond(big-desktop) {
    width: 1290px;
  }
  @include respond(huge-desktop) {
    width: 1460px;
  }
}

@mixin small-container {
  width: 765px;

  @include respond(tab-land) {
    width: 585px;
  }
  @include respond(big-desktop) {
    width: 1000px;
  }
  @include respond(huge-desktop) {
    width: 1201px;
  }
}

@mixin paragraph {
  font-size: 21px;
  @include respond(tab-land) {
    font-size: 16px;
  }
  @include respond(big-desktop) {
    font-size: 26px;
  }
  @include respond(huge-desktop) {
    font-size: 30px;
  }
}

@mixin title {
  font-size: 44px;
  @include respond(tab-land) {
    font-size: 35px;
  }
  @include respond(tab-port) {
    font-size: 28px;
  }
  @include respond(big-desktop) {
    font-size: 52px;
  }
  @include respond(huge-desktop) {
    font-size: 62px;
  }
}

@mixin btn-font {
  font-size: 20px;
  @include respond(phone) {
    font-size: 24px;
  }
  span {
    font-family: "FoundersGrotesk-Light";
    @include respond(phone) {
      font-size: 24px;
    }
  }
}

// MEDIA QUERY MANAGER

/* 
  0 - 600px:       Phone
  600 - 900px:     Tablet portrait
  900 - 1200px:    Tablet landscape
  [1200 - 1800]:   Normal styles apply  
  1800px +:        Big desktop
  */

/* 
  $breakpoint arguement choices: 
  - phone
  - tab-port
  - tab-land
  - big-desktop
  */

// Media querys are not affected by html font-size
// rems don't work well
// 1em = 16px
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      // max 600px
      @content;
    }
  }
  @if $breakpoint == min-phone {
    @media only screen and (min-width: 37.5em) {
      // min 600px
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      //900px
      @content;
    }
  }
  @if $breakpoint == min-tab-port {
    @media only screen and (min-width: 56.31em) {
      //900px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    //1200px
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == min-tab-land {
    @media only screen and (min-width: 75.06em) {
      //1200px
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    //1800px
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
  @if $breakpoint == huge-desktop {
    //2200px
    @media only screen and (min-width: 137.5em) {
      @content;
    }
  }
}
