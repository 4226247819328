.newsletter {
  background: $dark-grey;
  align-items: center;
  justify-content: flex-start;
  @include respond(min-phone) {
    min-height: calc(700px - 5.6rem);
  }

  &__container {
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__title {
    /* width: 300px; */
    align-items: center;
    justify-content: center;
    margin: 0 auto 50px;

    @include respond(tab-land) {
      /* width: 236px; */
      margin-bottom: 40px;
    }
    @include respond(tab-port) {
      /* width: 175px; */
      margin-bottom: 30px;
    }
    @include respond(big-desktop) {
      /* width: 475px; */
      margin-bottom: 70px;
    }
    @include respond(huge-desktop) {
      /* width: 540px; */
    }
    &--mobile {
      width: 210px;
      margin: 0 auto 26px;
    }
  }

  &__benvenuti {
    @include title;
    text-transform: uppercase;
  }

  &__famiglia {
    margin-top: 10px;
    height: 30px;
    @include respond(tab-land) {
      height: 24px;
    }
    @include respond(tab-port) {
      height: 20px;
    }
    @include respond(big-desktop) {
      height: 36px;
    }
    @include respond(huge-desktop) {
      height: 45px;
    }
  }

  &__comment {
    font-size: 20px;
    text-align: center;
    margin: 0 auto 35px;
    @include paragraph;
    @include respond(phone) {
      width: 68%;
    }
    @include respond(tab-land) {
      margin-bottom: 30px;
    }
    @include respond(tab-port) {
      margin-bottom: 20px;
    }
    @include respond(big-desktop) {
      margin-bottom: 40px;
    }
  }

  &__form {
    position: relative;
    align-items: center;
    @include respond(phone) {
      width: 72%;
    }
  }

  &__input {
    margin-bottom: 7rem;
    width: 535px;
    height: 50px;
    border: none;
    outline: none;
    text-align: center;
    @include paragraph;
    font-family: "FoundersGrotesk-Light";
    @include respond(tab-land) {
      width: 430px;
      height: 45px;
    }
    @include respond(tab-port) {
      width: 395px;
      height: 35px;
    }
    @include respond(phone) {
      width: 100%;
    }
    @include respond(big-desktop) {
      width: 700px;
      height: 70px;
    }
    @include respond(huge-desktop) {
      width: 800px;
      height: 80px;
      margin-bottom: 100px;
    }

    &::placeholder {
      font-family: "FoundersGrotesk-Regular";
      color: $dark-grey;
    }
  }

  &__error {
    font-size: 1.7rem;
    transform: translate(0, -5rem);
    color: $orange;
    position: absolute;
    bottom: 3rem;
  }

  &__btn {
    @include btn-unstyled;
    @include orange-btn;
  }
}
