.social {
  justify-content: flex-start;
  align-items: center;
  @include respond(min-phone) {
    min-height: calc(700px - 5.6rem);
  }

  &__container {
    flex: 1;
    align-items: center;
    justify-content: center;
    @include big-container;
    @include respond(phone) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 70px;

    @include respond(tab-land) {
      margin-bottom: 60px;
    }
    @include respond(tab-port) {
      margin-bottom: 40px;
    }
    @include respond(phone) {
      margin: 0 auto 60px;
    }

    @include respond(big-desktop) {
      margin-bottom: 90px;
    }
    @include respond(huge-desktop) {
      margin-bottom: 110px;
    }
  }

  &__tutti {
    @include title;
    text-transform: uppercase;
    margin-right: 11px;
    @include respond(tab-land) {
      margin-right: 8px;
    }
    @include respond(big-desktop) {
      margin-right: 13px;
    }
    @include respond(huge-desktop) {
      margin-right: 15px;
    }
  }

  &__giorni {
    margin-top: 13px;
    height: 30px;
    @include respond(tab-land) {
      margin-top: 11px;
      height: 24px;
    }
    @include respond(tab-port) {
      margin-top: 9px;
      height: 19px;
    }
    @include respond(big-desktop) {
      margin-top: 15px;
      height: 35px;
    }
    @include respond(huge-desktop) {
      margin-top: 18px;
      height: 44px;
    }
  }

  &__carousel {
    width: 75%;
    max-width: 35rem;
  }

  &__posts {
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
  }

  &__post {
    width: 23%;
  }

  &__btn {
    @include btn-unstyled;
    @include orange-btn;
    margin-top: 90px;

    @include respond(tab-land) {
      margin-top: 80px;
    }
    @include respond(tab-port) {
      margin-top: 60px;
    }
    @include respond(big-desktop) {
      margin-top: 110px;
    }
    @include respond(huge-desktop) {
      margin-top: 130px;
    }
  }
}
