*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; /* GP - It will inherit body style */
}

/* Root style, reference for REM unit */
html {
  // 1rem setup
  font-size: 62.5%; /* GP - 10px (what we want) * 16px (default root-font-size) / 100 */
  scroll-behavior: smooth;

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12px -> 12/16
  }

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 9px -> 9/16
  }

  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px -> 8/16
  }
}

body {
  box-sizing: border-box;
  font-family: "FoundersGrotesk-Light";
}

#root {
  overflow: hidden;
}

h1 {
  font-weight: unset;
}

::selection {
  background-color: $darker-grey;
  color: $white;
}
