.card {
  &__container {
    position: relative;
    @include respond(phone) {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__title {
    @include title;
    text-transform: uppercase;
    margin: 3rem auto;

    @include respond(phone) {
      font-size: 44px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0 auto;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  &__img {
    width: 100%;
    @include respond(phone) {
      display: none;
    }
    &--mobile {
      width: 100%;
      cursor: pointer;

      @include respond(min-phone) {
        display: none;
      }
    }
  }

  &__description {
    margin-top: 2.2rem;
    text-align: center;
    @include paragraph;

    @include respond(phone) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $orange;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20%;
      cursor: pointer;
    }
  }
}
