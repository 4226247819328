.map {
  &__container {
    width: 100%;
    height: 100%;
    //filter: grayscale(60%);
  }
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}
