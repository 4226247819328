/* FOUNDERS GROTESK */
@font-face {
  font-family: "FoundersGrotesk-Bold";
  src: url("../../css/fonts/FoundersGrotesk-Bold.otf");
}

@font-face {
  font-family: "FoundersGrotesk-Light";
  src: url("../../css/fonts/FoundersGrotesk-Light.woff");
}

@font-face {
  font-family: "FoundersGrotesk-Medium";
  src: url("../../css/fonts/FoundersGrotesk-Medium.otf");
}

@font-face {
  font-family: "FoundersGrotesk-Regular";
  src: url("../../css/fonts/FoundersGrotesk-Regular.otf");
}

/* BOOKMANIA */
@font-face {
  font-family: "Bookmania-Lt";
  src: url("../../css/fonts/Bookmania-Light.otf");
}
