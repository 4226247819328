.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 10rem;
  padding: 7rem 10% 0;
  @include respond(phone) {
    padding: 5.5rem 0 0;
  }

  &__container {
    width: 100%;
    max-width: 1600px;
    justify-content: space-between;
    margin: 0 auto;
  }

  &__links {
    width: 30%;

    @include respond(phone) {
      display: none;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__logo {
    width: 21%;
    max-width: 400px;
    position: absolute;
    top: 5.3rem;
    left: 50%;
    transform: translate(-50%, 0);
    @include respond(phone) {
      width: 60%;
    }

    &-container {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @include respond(phone) {
        width: 100%;
      }
    }

    &--flap {
      width: 5.5rem;
      transform: translate(0, -7rem);
      animation-name: fadeIn;
      animation-duration: 0.2s;
      @include respond(phone) {
        transform: translate(0, -6rem);
        width: 55px;
      }
    }
  }

  &__link {
    font-family: "FoundersGrotesk-Light";
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2.1rem;

    &--left {
      margin-right: 14%;
    }

    &--right {
      margin-left: 14%;
    }
  }

  &__actions {
    position: relative;
    height: 40px;
    transform: translate(0, -7px);
  }

  &__action {
    width: 40px;
    height: 40px;

    &--right {
      margin-left: 1.8rem;
    }
  }

  &__action,
  &__action--right {
    &:hover {
      background: $orange;
      border-radius: 50%;
      transition: background 0.3s ease;
    }
  }

  &__img {
    width: 40px;
    transform: translate(0, 1px);

    &--orange {
      position: absolute;

      width: 40px;
      height: 40px;
    }
  }
}
