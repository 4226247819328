.home {
  position: relative;

  &__links--mobile {
    @include respond(min-phone) {
      display: none;
    }
    width: 171px;
    align-items: center;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &__link {
    @include btn-unstyled;
    @include shallow-btn;
    width: 100%;
    margin-bottom: 30px;
    font-size: 24px;
  }

  &__actions {
    width: 100%;
    justify-content: center;
  }

  &__action {
    width: 45px;
    margin: 0 7px;
  }

  &__instagram,
  &__whatsapp {
    width: 100%;
  }
}
