.contact {
  background: $dark-grey;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5.6rem);
  @include respond(min-phone) {
    min-height: calc(700px - 5.6rem);
  }

  @include respond(phone) {
    height: auto;
  }

  &__container {
    @include big-container;
    height: 35rem;
    justify-content: space-between;
    @include respond(phone) {
      width: 80%;
      height: 1040px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__map {
    width: 45%;
    @include respond(phone) {
      width: 100%;
      height: 340px;
      margin-bottom: 30px;
    }
  }

  &__info {
    width: 45%;
    justify-content: space-between;
    @include respond(phone) {
      width: 100%;
      text-align: center;
    }
  }

  &__title {
    @include title;
    text-transform: uppercase;
    margin-bottom: 18px;

    &--mobile {
      font-size: 24px;
      margin: 0 auto 27px;
      text-transform: uppercase;
      line-height: 25px;
      text-align: center;
    }
  }

  &__data {
    @include paragraph;
    @include respond(phone) {
      font-size: 20px;
    }
  }

  &__actions {
    justify-content: space-between;

    @include respond(phone) {
      margin-top: 6rem;
      flex-direction: column;
      align-items: center;
    }
  }

  &__action {
    width: 40%;
    max-width: 180px;
    justify-content: space-between;

    @include respond(phone) {
      width: 100%;
    }
    &-title {
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 2.2rem;

      @include respond(tab-land) {
        margin: 0;
        min-height: 7rem;
        font-size: 18px;
      }
      @include respond(tab-port) {
        min-height: 6rem;
        font-size: 13px;
      }
      @include respond(phone) {
        @include btn-font;
        min-height: unset;
        margin: 0 auto 12px;
      }

      span.tab-land-max {
        text-transform: uppercase;
      }

      span {
        text-transform: none;
      }
    }
  }

  &__btn {
    @include btn-unstyled;
    @include orange-btn;
    @include respond(tab-port) {
      width: 140px;
    }
    @include respond(phone) {
      margin: 0 auto 35px;
      width: 171px;
    }
  }
}
