.footer {
  width: 100%;

  &__container {
    width: 100%;
  }

  &__fix--mobile {
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 50%;
    max-width: 200px;
  }

  &__brand {
    padding-bottom: 16px;
    font-size: 16px;
    font-family: "FoundersGrotesk-Light";
    height: 35rem;
    background: $orange;
    align-items: flex-end;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
    color: $black;
  }
}
