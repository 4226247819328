.menu {
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5.6rem);
  @include respond(min-phone) {
    min-height: calc(700px - 5.6rem);
  }

  @include respond(phone) {
    justify-content: center;
    height: unset;
    min-height: 100vh;
  }

  &__cards {
    @include big-container;
    align-items: center;
    justify-content: space-between;
    margin: 7rem 0 6rem;
    @include respond(phone) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin: 140px 0 1rem;
      padding: 0 45px;
    }
  }

  &__card {
    width: 28%;
    @include respond(phone) {
      width: 100%;
    }
  }

  &__btn {
    @include btn-unstyled;
    @include orange-btn;
    @include respond(phone) {
      margin: 10px auto 90px;
    }
  }
}
