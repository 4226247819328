.fix {
  width: 100%;
  height: 5.6rem;
  padding: 0 6%;
  background: $white;
  align-items: center;
  justify-content: space-between;
  @include respond(phone) {
    display: none;
  }

  &--fixed {
    position: fixed;
    top: calc(100vh - 5.6rem);
    left: 0;
    z-index: 500;
  }

  &__container {
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    justify-content: space-between;
  }

  &__address,
  &__phone {
    @include btn-font;
    text-transform: uppercase;
    display: flex;
    flex: 1;
    word-spacing: 5px;
  }

  &__phone {
    justify-content: flex-end;
  }

  &__dot {
    font-size: 1rem;
    margin: 0 1rem 0;
    line-height: 2rem;
  }

  &__img {
    width: 15.5rem;
  }
}
